import React from "react"
import style from "./style.module.scss"

export default () => {
  return (
    <div className="op__block"id="contact__header" data-aos="fade-up" data-aos-delay="200">
      <div className="container-fluid">
        <div className={style.form}>
          <h2 className="mb-5" >
            Ready to make some software magic?
            <br />
            The first step is connecting
          </h2>
          <form method="post" action="https://formspree.io/f/xjvlbwlq">

          <div className="row">
            <div className="col-lg-9">
              <div className={style.form__item}>
                <label htmlFor="name">Your full name</label>
                <input className="form-control" required type="text" id="name" name="name" />
              </div>
            </div>
            <div className="col-lg-5">
              <div className={style.form__item}>
                <label htmlFor="email">Email</label>
                <input className="form-control" required type="text" id="_replyto" name="email"/>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={style.form__item}>
                <label htmlFor="phone">Phone Number</label>
                <input className="form-control" required type="text" id="phone" name="phone" />
              </div>
            </div>
           
            {/* <div className="col-lg-6">
              <div className={style.form__item}>
                <label htmlFor="country">Country</label>
                <input className="form-control" required type="text" id="country" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className={style.form__item}>
                <label htmlFor="file">Attach file</label>
                <input className="form-control" required type="file" id="file" />
              </div>
            </div> */}
            <div className="col-lg-9">
              <div className={style.form__item}>
                <label htmlFor="message">Description</label>
                <textarea className="form-control" required id="message" name="message"></textarea>
              </div>
            </div>
          </div>
          <div className="pt-3 mb-4">
            <button className="op__btn op__btn--blue">Send proposal</button>
          </div>
          </form>
          <p className={`${style.form__disclaimer} text-muted`}>
            This form collects your personal details so that we can contact you
            back to raise opportunities for cooperation, and we need your
            consent on that. You can withdraw your consent at any time by
            writing to us at team@thefoundation.cloud.
          </p>
          <div className={style.form__fig1}></div>
          <div className={style.form__fig2}></div>
          <div className={style.form__fig3}></div>
          <div className={style.form__fig4}></div>
          <div className={style.form__fig5}>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
